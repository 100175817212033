<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t('profileView.subscription.renewModal.title')"
    :visible="data.visible"
    :onCancel="closeModal"
    :width="360"
    class="renew-subscription-modal"
  >
    <div class="renew-subscription-modal__content">
      <a-alert :message="error" type="warning" v-if="error" show-icon style="margin-bottom: 20px" />
      <a-radio-group v-model:value="showType">
        <a-radio-button value="token">
          {{ i18n.t('profileView.subscription.renewModal.token') }}
        </a-radio-button>
        <a-radio-button value="point">
          {{ i18n.t('profileView.subscription.renewModal.point') }}
        </a-radio-button>
      </a-radio-group>
      <a-form
        :model="dataSourceToken"
        @finish="renewToken"
        name="renew_token"
        ref="renew_token"
        v-if="showType == 'token'"
      >
        <a-form-item
          ref="writeOffAccount"
          name="writeOffAccount"
          class="input__wrapper"
          :label="i18n.t('start.writeOffAccount')"
        >
          <a-input
            :value="
              i18n.t('start.inputAccountCurrency', {
                name: i18n.t('start.name' + dataSourceToken.account.attributes.kind),
                amount: dataSourceToken.account.attributes.amount,
                currency: dataSourceToken.account.attributes.amount_currency,
              })
            "
            size="large"
            type="text"
            :disabled="true"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.price')">
          <a-input
            v-model:value="dataSourceToken.amount"
            size="large"
            style="width: 100%"
            type="number"
            step="0.01"
            :disabled="true"
          >
            <template #addonAfter>
              <p class="action__title">MTH</p>
            </template>
          </a-input>
        </a-form-item>
        <sdButton class="action__button" size="large" :disabled="isLoading" type="primary" htmlType="submit" raised>
          {{ i18n.t('profileView.subscription.renew') }}
        </sdButton>
      </a-form>
      <a-form
        :model="dataSourcePoint"
        @finish="renewPoint"
        name="renew_token"
        ref="renew_token"
        v-if="showType == 'point'"
      >
        <a-form-item
          ref="writeOffAccount"
          name="writeOffAccount"
          class="input__wrapper"
          :label="i18n.t('start.writeOffAccount')"
        >
          <a-input
            :value="
              i18n.t('start.inputAccountCurrency', {
                name: i18n.t('start.name' + dataSourcePoint.account.attributes.kind),
                amount: dataSourcePoint.account.attributes.amount,
                currency: dataSourcePoint.account.attributes.amount_currency,
              })
            "
            size="large"
            type="text"
            :disabled="true"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item ref="amount" name="amount" class="input__wrapper" :label="i18n.t('start.price')">
          <a-input
            v-model:value="dataSourcePoint.amount"
            size="large"
            type="number"
            step="0.01"
            :disabled="true"
            style="width: 100%"
          >
            <template #addonAfter>
              <p class="action__title">POINT</p>
            </template>
          </a-input>
        </a-form-item>
        <sdButton class="action__button" size="large" :disabled="isLoading" type="primary" htmlType="submit" raised>
          {{ i18n.t('profileView.subscription.renew') }}
        </sdButton>
      </a-form>
    </div>
  </sdModal>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const RenewSubscriptionModal = {
  name: 'RenewSubscriptionModal',

  props: {
    data: {
      type: Object,
      default() {
        return {
          visible: false,
        };
      },
    },
  },
  emits: ['dataSended'],

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());

    const subscriptionData = computed(() => state.profile.subscription);
    const isLoading = computed(() => state.shop.loading);
    // const error = computed(() => state.shop.error);

    const token_account = computed(() => state.accounts.data.find((e) => e.attributes.kind == 'token'));
    const point_account = computed(() => state.accounts.data.find((e) => e.attributes.kind == 'point'));
    const showType = ref('token');

    const error = computed(() => {
      const err = state.shop.error;
      if (err) {
        if (typeof err === 'object') {
          return Object.entries(err).map((value) => value[1][0]);
        } else {
          return err == 500 ? i18n.t('error.500') : err;
        }
      }
      return false;
    });

    const dataSourceToken = ref({
      account: token_account,
      amount: 100,
    });
    const dataSourcePoint = ref({
      account: point_account,
      amount: 500,
    });
    const renewSubscription = async (type) => {
      const accounts = {
        point: dataSourcePoint.value.account.id,
        token: dataSourceToken.value.account.id,
      };
      const data = ref({
        account_id: accounts[type],
        kind: 4,
      });

      await dispatch('purchaseSubscriptionInShop', data.value);
      if (!error.value) {
        emit('dataSended');
        closeModal();
      }
      return;
    };

    const renewToken = () => {
      renewSubscription('token');
    };

    const renewPoint = () => {
      renewSubscription('point');
    };

    const closeModal = () => {
      dispatch('clearShopErrors');
      emit('update:data', { visible: false });
    };

    return {
      i18n,
      closeModal,
      subscriptionData,
      dataSourceToken,
      dataSourcePoint,
      renewToken,
      renewPoint,
      error,
      isLoading,
      showType,
    };
  },
};
export default RenewSubscriptionModal;
</script>
<style scoped lang="scss">
.renew-subscription-modal {
  .ant-radio-group {
    margin-bottom: 20px;
  }
}
</style>
